import React from 'react';

function Loader () {

        return (
            <div className="loader_main">
                <div className="loadernox">
                    {/* <img src={`${process.env.PUBLIC_URL}/Assets/images/car-loader_200.gif`} alt="loader" /> */}
                 <div className="spinner"></div> 
                </div>
            </div>
        );
}

export default Loader;