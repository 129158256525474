export const formValidate = (inputs, nomineeDOBError) => {

    const errors = {};

    // console.log(inputs,"owener deatils validation");

    if (!inputs.name) {
        errors.name = 'Please enter name';
    }

    if (!inputs.email) {
        errors.email = 'Please enter email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) {
        errors.email = 'Invalid email address';
    }

    if (!inputs.mobile) {
        errors.mobile = 'Please enter mobile';
    } else if (inputs.mobile.length < 10) {
        errors.mobile = 'Please enter 10 digit mobile no.';
    } else if (!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(inputs.mobile)) {
        errors.mobile = 'Invalid mobile no.';
    }

    if (!inputs.gender) {
        errors.gender = 'Please select gender';
    }

    if (!inputs.dateOfBirth) {
        errors.dateOfBirth = inputs.ownerShipType == 1 ? 'Please select DOB' : 'Please select DOI';
    }

    if (!inputs.pan) {
        errors.pan = 'Please enter pan no.';
    } else if (inputs.pan.length < 10) {
        errors.pan = 'Please enter 10 digit pan no.';
    } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(inputs.pan)) {
        errors.pan = 'Invalid pan no.';
    }

    if (!inputs.occupation) {
        errors.occupation = 'Please select occupation';
    }
    if (!inputs.maritalStatus) {
        errors.maritalStatus = 'Please select marital status';
    }
    if (!inputs.addressOne) {
        errors.addressOne = 'Please enter address1';
    }
    if (!inputs.addressTwo) {
        errors.addressTwo = 'Please enter address2';
    }
    if (!inputs.pincode) {
        errors.pincode = 'Please enter pincode';
    } else if (inputs.pincode.length < 6) {
        errors.pincode = 'Please enter 6 digit pincode.';
    }
    if (!inputs.stateId) {
        errors.stateId = 'Please enter state';
    }
    if (!inputs.nomineeName) {
        errors.nomineeName = 'Please enter nominee name';
    }
    if (!inputs.nomineeDOB) {
        errors.nomineeDOB = 'Please select nominee dob';
    }
    if (!inputs.nomineeRelation) {
        errors.nomineeRelation = 'Please select customer relation';
    }

    if (nomineeDOBError != "") {
        errors.nomineeDOB = nomineeDOBError;
    }

    if (nomineeDOBError != "") {
        errors.nomineeDOB = nomineeDOBError;
    }

    //Password Errors

    return errors;
}

export const vehicleDetailFormValidate = (inputs) => {

    const errors = {};

    if (!inputs.registrationNoFirst) {
        errors.registrationNoFirst = 'Please enter vehicle registration no.';
    }

    if (!inputs.registrationNoLast) {
        errors.registrationNoLast = 'Please enter vehicle registration no.';
    }
    if (!inputs.engineNo) {
        errors.engineNo = 'Please enter vehicle engine no.';
    } else if (inputs.engineNo.length < 6) {
        errors.engineNo = 'Minimum 6 characters required';
    }
    if (!inputs.chasisNo) {
        errors.chasisNo = 'Please Enter Vehicle Chassis No.';
    } else if (inputs.chasisNo.length < 17) {
        errors.chasisNo = "Minimum 17 characters required";
    }
    if (!inputs.financer) {
        errors.financer = 'Please select financer';
    }
    if (!inputs.financerType) {
        errors.financerType = 'Please select financer type';
    }
    if (!inputs.financerBranch) {
        errors.financerBranch = 'Please enter financer city/branch';
    }
    ///financerBranch
    //Password Errors

    return errors;
}

export const PreviousPolicyAndCPADetailsFormValidate = (inputs, newpolicycoveragetype_id, policyType_id) => {

    const errors = {};
    // console.log(inputs, "ppca validation");
    if (!inputs.previousInsurerId) {
        errors.previousInsurerId = 'Please select previous insurer';
    }
    if (!inputs.previousPolicyNo) {
        errors.previousPolicyNo = 'Please enter policy no.';
    }
    if (!inputs.cpaOtpReason) {
        errors.cpaOtpReason = 'Please select CPA opt-out reason';
    }

    if (policyType_id == 3 && newpolicycoveragetype_id == 3) {
        if (!inputs.previousinsueridtp) {
            errors.previousinsueridtp = 'Please select previous insurer id';
        }

        if (!inputs.previouspolicytpstartdate) {
            errors.previouspolicytpstartdate = 'Please select start date';
        }

        if (!inputs.previouspolicynumbertp) {
            errors.previouspolicynumbertp = 'Please enter previous tp policy number';
        }

        if (!inputs.previouspolicytpexpirydate) {
            errors.previouspolicytpexpirydate = 'Please select expiry date';
        }
    }

    return errors;

}

export const KYCDocumentFormValidate = (inputs) => {
    const errors = {};
    if (inputs.iskycdone == true) {
        // console.log("kyc done")
        if (!inputs.kycnumber) {
            errors.kycnumber = 'Please enter your kyc number';
        }
    }
    else {
        // console.log("kyc not done")
        if (!inputs.docverificationid) {
            errors.docverificationid = 'Please select document type';
        }
        let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

        if (!inputs.docverificationnumber) {
            errors.docverificationnumber = 'Please enter document number';
        }
        else if (inputs.docverificationid == 2) {
            if (!regex.test(inputs.docverificationnumber)) {
                errors.docverificationnumber = "Please check the PAN entered."
            }
        }
    }
    // console.log(inputs,"is kyc done");
    return errors;
}


export const PreviousPolicyTypeFormValidate = (inputs, policyType, isTpSelected, regYearDayDiffFromCurrentDate) => {

    const errors = {};
    // console.log(inputs, " PreviousPolicyTypeFormValidate");
    if (isTpSelected || policyType == 3 && regYearDayDiffFromCurrentDate > 0) {
        // if (!inputs.previousinsueridtp) {
        //     errors.previousinsueridtp = 'Please select previous insurer id';
        // }

        if (!inputs.previouspolicytpstartdate) {
            errors.previouspolicytpstartdate = 'Please select start date';
        }

        // if (!inputs.previouspolicynumbertp) {
        //     errors.previouspolicynumbertp = 'Please enter previous tp policy number';
        // }

        if (!inputs.previouspolicytpexpirydate) {
            errors.previouspolicytpexpirydate = 'Please select expiry date';
        }

    }
    if (isTpSelected == false) {
        if (!inputs.previouspolicyodexpirydate) {
            errors.previouspolicyodexpirydate = 'Please select expiry date';
        }

        // if (!inputs.previousInsurerId) {
        //     errors.previousInsurerId = 'Please select previous insurer';
        // }

        // if (!inputs.previouspolicynumberod) {
        //     errors.previouspolicynumberod = 'Please enter previous policy no.';

        // }
    }
    return errors;
}

export const LoginValidate = (inputs) => {

    const errors = {};

    if (!inputs.loginId) {
        errors.loginId = 'Please enter user ID';
    }
    if (!inputs.password) {
        errors.password = 'Please enter password';
    }
    // console.log(inputs,"is kyc done");
    return errors;
}

export const Sharedeatils = (customername,customeremail,customermobile) => {
    const errors = {};
    if (!customername) {
        errors.customername = 'Please enter customer name';
    }

    if (!customeremail) {
        errors.customeremail = 'Please enter customer email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customeremail)) {
        errors.customeremail = 'Invalid email address';
    }

    if (!customermobile) {
        errors.customermobile = 'Please enter customer mobile';
    } else if (customermobile.length < 10) {
        errors.mobile = 'Please enter 10 digit mobile no.';
    } else if (!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(customermobile)) {
        errors.customermobile = 'Invalid mobile no.';
    }
    return errors;
}