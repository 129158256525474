import axios from 'axios';
import CryptoService from './CryptoService';

export default class InterceptorService {

    static Start() {

        //Request Interceptor
        var aesKey = CryptoService.AesKey();
        axios.interceptors.request.use(
            request => {
                if (request.url.indexOf('/token') < 1) {
                    const token = sessionStorage.getItem('token');
                    const sessionId = sessionStorage.getItem('sessionId');
                    //console.log('sessionId', sessionId);
                    if (token || sessionId) {
                        request.headers['token'] = token
                        request.headers['sessionId'] = (sessionId =='' || sessionId == null ? '' : sessionId)
                    }
                    else {
                        sessionStorage.clear();
                        //window.location = '/login'
                    }
                }

                var encrypted_data = CryptoService.AesEncrypt(JSON.stringify(request.data), aesKey);
                var encrypted_aesKey = CryptoService.RSAEncrypt(aesKey);

                var new_request_date = {
                    'secret': encrypted_aesKey,
                    'requestData': encrypted_data
                };
            
                //  request.data = new_request_date;

                return request
            },
            error => {
                Promise.reject(error)
            }
        );

        //Response Interceptor
        axios.interceptors.response.use(
            response => {
                //console.log('interceptor response', response.data);
                return response
            },
            err => {
                return Promise.reject(err);
            }
        )
    }
}