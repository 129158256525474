import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import MainPage from './Component/Layout/MainLayout';
import Header from './Component/Common/Header';
import Footer from './Component/Common/Footer';
import configureStore from "./store/index";

const store = configureStore();

const App = () => (
  <BrowserRouter >
    <Header />
    <Provider store={store}>
    <MainPage />
    </Provider>
    <Footer />
    </BrowserRouter>
);

export default App;


