import { combineReducers } from 'redux'
import serviceReducer from "../reducers/ServiceReducer";
import dbReducer from "../reducers/SessionDBReducer";



const rootReducer = combineReducers({
    serviceReducer,
    dbReducer,
})

export default rootReducer
