import {
    User_Detail, Page_Access,
} from '../types/SessionDBActionTypes';

const dbReducer = (state = {}, action) => {
    switch (action.type) {
        case User_Detail:
            return {
                ...state,
                type: User_Detail,
                user_details: action.payload.response
            };
            case Page_Access:
            return {
                ...state,
                type: Page_Access,
                page_access : action.payload.response
            }
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}

export default dbReducer;