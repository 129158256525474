/**
 * Author : Navin Jaiswal
 * Company : Gramcover Insurance Brokers Pvt. Ltd.
 * Date : 07 Jan 2022
 */
export default class ConstantValues {

    static Issuer = "gramcover";
    static Key   = "ThisismySecretKey"

    static vehicleList = [
       
    ]
}
