import React, { useEffect, useState } from 'react';
import ServicesURL from './Services/ServicesURL';
import { connect } from 'react-redux';
import * as actionCreators from "../src/Action/ServiceAction";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { base64ToArrayBuffer } from './helper/Liberary';
import Form from 'react-bootstrap/Form'
import { LoginValidate } from './validator/Validator';
import axios from "axios";
import Loader from "../src/Component/Common/Loader";


const LoginPage = () => {

    const [loginId, setLoginId] = useState("");
    const [password, setPassword] = useState("");
    const [apiCall, setApiCall] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    // const [partnerId, setPartnerId] = useState("");
    const [formValidationError, setFormValidationError] = useState("");
    const navigate = useNavigate();
    // const [partner_id, setPartner_id] = useState('');

    const validateSpace = (e) => {
        //console.log(e.target.value,'dfg');
        if (e.target.value.includes(' ')) {
            //console.log("validateSpace");
            e.target.value=e.target.value.trim();
        }
        setLoginId(e.target.value)
    }

    const getHandlerData = () => {
        setLoading(true)
        const defaultData = {
            loginId: loginId,
            password: password
        }
        const validationErrors = LoginValidate(defaultData);
        // console.log(formData, 'validation check')
        const noErrors = Object.keys(validationErrors).length == 0 ? false : true;
        // console.log(noErrors)
        setFormValidationError(validationErrors);
        if (!noErrors) {
            var params = {
                "username": loginId,
                "password": password
            };

            var headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'token': sessionStorage.getItem('token')
            }

            axios.post(ServicesURL.getPartnerDetailsUrl + '/token', params, { headers: headers })
                .then((response) => {
                    setLoading(false);
                    // console.log(response.data);
                    if (response.data.StatusCode == 200) {
                        sessionStorage.setItem('token', response.data.Result.token)
                        sessionStorage.setItem('name', response.data.Result.name)

                        Swal.fire({
                            title: "Login Success",
                            icon: "success",
                            confirmbuttonText: "OK",
                            confirmButtonColor: '#b5daa3'
                        });
                        navigate("/car/motor-insurance")
                    } else {
                        sessionStorage.clear();

                        Swal.fire({
                            icon: 'warning',
                            title: 'Invalid user!',
                            text: 'Invalid user',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#b5daa3',
                            showLoaderOnConfirm: false
                        });
                    }
                })
                .catch(error => {
                    console.log('error', error);

                    Swal.fire({
                        icon: 'warning',
                        title: 'Something went wrong...!',
                        text: 'Something went wrong, please try again',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#b5daa3',
                        showLoaderOnConfirm: false
                    });
                });
        }
    }

    return (
        <div>
            <div className="col-sm-12 d-flex justify-content-center"> {loading ? <Loader /> : null}</div>
            <div className="container">
                <div className="row mt-4">
                    <div className="row g-2 justify-content-sm-center">
                        <div className='col-12 col-sm-6 col-md-4'>
                            <div className='row quotemodeform'>
                                <div className="card round shadow">
                                    <div className="card-body">
                                        <p style={{ color: "red" }}>{ }</p>

                                        <div className="col-sm-11">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    name="loginId"
                                                    maxLength={50}
                                                    defaultValue={loginId}
                                                    pattern="/\s/g"
                                                    onChange={(e) => {
                                                        // setInputValue(e.target.value.replace(/\s/g, ''));
                                                        validateSpace(e)
                                                    }}
                                                    id="mobile"
                                                    placeholder=" User name"
                                                />
                                                <label htmlFor="mobile">&nbsp; User Name<span className="text-danger">*</span> </label>
                                            </Form.Floating>
                                            <span style={{ color: "red" }}>{formValidationError.loginId ? formValidationError.loginId : ""} </span>
                                        </div><br />

                                        <div className="col-sm-11">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    maxLength={25}
                                                    defaultValue={password}
                                                    onChange={(e) => { setPassword(e.target.value) }}
                                                    id="Password"
                                                    placeholder="Password"
                                                />
                                                <label htmlFor="mobile">&nbsp; Password<span className="text-danger">*</span> </label>
                                            </Form.Floating>
                                            <span style={{ color: "red" }}>{formValidationError.password ? formValidationError.password : ""} </span>
                                        </div>

                                        <div className="row g-3 mb-4 px-4 pb-4 mt-2">
                                            <div className="col-md-12 col-12 mx-auto text-center">
                                                <button type="submit" onClick={() => getHandlerData()} className="btn-green m-2">Login</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({ response: state.serviceReducer, });
export default connect(mapStateToProps, actionCreators)(LoginPage);
