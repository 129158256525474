/**
 * Author : Navin Jaiswal
 * Company : Gramcover Insurance Brokers Pvt. Ltd.
 * Date : 22 Dec 2021
 */

import axios from "axios";
import ServicesURL from '../Services/ServicesURL';
import ConstantValues from '../constant/ConstantValues';
import { SERVICE_BEGIN, SERVICE_SUCCESS, SERVICE_FAILURE } from "../types/ServiceActionTypes";

export const serviceBegin = (methodName) => ({
    type: SERVICE_BEGIN,
    name: methodName
});

export const serviceSuccess = (response, methodName) => ({
    type: SERVICE_SUCCESS,
    payload: { response },
    name: methodName
});

export const serviceError = (error, methodName) => ({
    type: SERVICE_FAILURE,
    payload: { error },
    name: methodName//"Error"
});

var headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Issuer': ConstantValues.Issuer,
    'Key': ConstantValues.Key
    // 'Authorization': '',//localStorage.getItem("token")
    // 'sessionId':'' 
    //'Token': sessionStorage.getItem('token')
}

var headerMultipart = {
    'Content-Type': 'multipart/form-data',
    'Authorization': '',
}

export function callGetToken(methodName) {
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.get(ServicesURL.motorMasterUrl + methodName, { headers })
            .then((response) => {
            if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };
}


export function callGCPostMasterService(methodName, token, params) {
   //headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.post(ServicesURL.motorMasterUrl + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}

export function callGCPostKycService(methodName, token, params) {
  //  headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.post(ServicesURL.getCkycStatusUrl + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}

export function callGCPostMoterMasterService(methodName, token, params) { // this is only testing purpose, onlive it will remove
   // headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        //console.log('callGCPostMoterMasterService ',headers)
        return axios.post(ServicesURL.motorUrl + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}

export function callGCPostMoterMaster1Service(methodName, token, params) { // this is only testing purpose, onlive it will remove
   // headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.post(ServicesURL.motorUrl1 + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}


export function callGCDownloadMotorPolicy(methodName, token, params) { // this is only testing purpose, onlive it will remove
   // headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.post(ServicesURL.downloadPolicyUrl + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}


export function callPartnerDetails(methodName, params) {
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.post(ServicesURL.getPartnerDetailsUrl + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}

export function callGCPostKycStatus(methodName, token, params) {
   // headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        return axios.post(ServicesURL.GetCkycStatus + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}
export function callGCShareDetailsService(methodName, token, params) {
    // headers.token = token;
    return dispatch => {
        dispatch(serviceBegin(methodName));
        //console.log('callGCPostMoterMasterService ',headers)
        return axios.post(ServicesURL.sharelink + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    dispatch(serviceError(response.data, methodName));
                } else {
                    dispatch(serviceSuccess(response.data, methodName));
                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };

}



export function getMoterQuotes(methodName, token, params, masterQuoteIdData) { // this is only testing purpose, onlive it will remove
  //  headers.token = token;
    return dispatch => {
        return axios.post(ServicesURL.motorUrl1 + methodName, params, { headers: headers })
            .then((response) => {
                if (response.data.StatusCode != 200) {
                    //document.getElementById('MainQuoteIDDiv_' + params.Insurer_Id).style = 'display:none';
                    dispatch(serviceError(response.data, methodName));

                } else {
                    let quote = response.data.Result.quoteDetails[0];
                    //document.getElementById('MainQuoteIDDiv_' + quote.Insurer_Id).style = 'display:block';
                    //document.getElementById('divLoading_' + quote.insurer_id).style = 'display:none';
                    //document.getElementById('divBasicPremium_' + quote.insurer_id).style = 'display:block';
                    //document.getElementById('divPremium_' + quote.insurer_id).style = 'display:block';
                    //document.getElementById('divBuyNow_' + quote.insurer_id).style = 'display:block';

                    document.getElementById('IDVValue_' + quote.insurer_id).textContent = (quote.newpolicycoveragetype_id == 2 ? 'N/A' : quote.insurer_vehicle_idv != null ? quote.insurer_vehicle_idv : 0);
                    document.getElementById('BasicPremium_' + quote.insurer_id).textContent = (quote.premiumwithoutgst != null ? quote.premiumwithoutgst : 0);
                    document.getElementById('Premium_' + quote.insurer_id).textContent = (quote.netpremium != null ? quote.netpremium : 0);
                    document.getElementById('MotorQuoteMasterID_' + quote.insurer_id).value = quote.motorquotemaster_id;
                    document.getElementById('MainQuoteIDDiv_' + quote.insurer_id).setAttribute('data-percentage', quote.netpremium != null ? quote.netpremium : 0);

                    //document.getElementById('labelCompare_' + quote.insurer_id).setAttribute('htmlFor', 'flexCheckDefault' + quote.motorquotemaster_id);
                    // (document.getElementsByClassName('compareCheckBox_' + quote.insurer_id)[0]).setAttribute('id', 'flexCheckDefault' + quote.motorquotemaster_id);
                    (document.getElementsByClassName('compareCheckBox_' + quote.insurer_id)[0]).setAttribute('MotorQuoteMasterID', quote.motorquotemaster_id);
                    (document.getElementsByClassName('compareCheckBox_' + quote.insurer_id)[0]).setAttribute('value', quote.insurer_id);

                    // document.getElementById("flexCheckDefault" + quote.motorquotemaster_id).setAttribute('disabled', (selectInsuranceIdForCompare.includes(quote.motorquotemaster_id) ? true : false));
                    // document.getElementById("flexCheckDefault" + quote.motorquotemaster_id).setAttribute('checked', (disableCheckBokForCompare && !selectInsuranceIdForCompare.includes(quote.motorquotemaster_id) ? true : false));

                }
            })
            .catch(error => dispatch(serviceError(error, methodName)));
    };
}
