import React from 'react'

const Footer = props => {
    return (
        <>
            <div>
                <footer className="footer">
                    © {new Date().getFullYear()} Copyright <a href="https://www.gramcover.com/" target="_blank">GramCover</a>
                </footer>
            </div>
        </>
    )
}



export default Footer
