import React, {useEffect} from 'react'
import '../../Assets/css/main.css';
import { Link } from '@material-ui/core';
import RoutePath from '../../route_Url/RoutePath';
import { useNavigate } from "react-router-dom";
import LoginPage from '../../LoginPage';

export const Header = (e) => {
  const navigate = useNavigate();
 const usertoken= sessionStorage.getItem("token");
 const username= sessionStorage.getItem("name");
  // removeItem = () => sessionStorage.removeItem("token")
  const goToHomePage = () => {
    navigate('/' + RoutePath.moterInsurance);

  }

  const getHandlerData = () => {
    // console.log("logout")
    sessionStorage.clear();
    navigate("/login");
  }
  // removeItem = () => sessionStorage.removeItem("token")
  const LoginPage = () => {
    navigate('/' + RoutePath.login);
  }


  return (
    <div>
      <nav className="navbar navbar-light navCustom">
        <div className="container">
          <Link onClick={() => goToHomePage()}> <img src={`${process.env.PUBLIC_URL}/Assets/images/logo.png`} alt="Gramcover" /></Link>
         
          {/* <div className="ms-auto tollfree-box">
            <div>Need Help Call Us</div>
            <span className='fa-solid fa-phone cuscircle'></span> 1800 XXX XX
          </div> */}
          <div style={{marginLeft:'100px', fontWeight:'bold'}}>
            <label> {usertoken? 'Hi! '+ username+' | ':''}</label>          
              {usertoken? (<Link style={{fontWeight:'bold'}} onClick={() => getHandlerData()}>Logout </Link>):(<Link onClick={() => LoginPage()}>Login</Link> )}
          </div>
        </div>
      </nav>
    </div>
  )
}


export default Header;