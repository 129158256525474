import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import cryptoRandomString from 'crypto-random-string';

export default class CryptoService {

    static AesEncrypt(plain_text, aesKey) {
        var encrypted_data = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(plain_text),
            CryptoJS.enc.Utf8.parse(aesKey),
            {
                iv: CryptoJS.enc.Utf8.parse(aesKey.substring(0, 16)),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
        
        return encrypted_data;
    }
    static AesDecrypt(encrypted_text, aesKey) {

        var decrypted_data = CryptoJS.AES.decrypt(
            encrypted_text,
            CryptoJS.enc.Utf8.parse(aesKey),
            {
                iv: CryptoJS.enc.Utf8.parse(aesKey.substring(0, 16)),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
        return decrypted_data;
    }
    static AesKey() {

        return cryptoRandomString({ length: 32 });
    }

    static RSAEncrypt(plain_text) {
        var encryptRSA = new JSEncrypt();
        encryptRSA.setPublicKey(atob(process.env.REACT_APP_PUBLIC_KEY));
        return encryptRSA.encrypt(plain_text);
    }
}