/**
 * Author : Navin Jaiswal
 * Company : Gramcover Insurance Brokers Pvt. Ltd.
 * Date : 22 Dec 2021
 */

class RoutePath {

    static root = "/";
    static login    = 'login';
    static addDetail    = 'addUser';
    static updateDetail = 'updateUser';
    static listDetail   = 'listUser';
    static moterInsurance   = 'car/motor-insurance';
    static moterQuotePage   = 'car/get-quote-page';
    static moterProposalPage   = 'car/proposal-page';
    static motorPaymentPage = 'Payment/PaymentMethod';
    static fourWheelerQuoteCompare    = 'car/moter-quote-compare';
    static paymentSuccessMotorQuote   = 'car/payment-success';
    static paymentFailureMotorQuote   = 'car/payment-failure';
    static paymentStatusMotorQuote    = 'car/payment-status';
    


    static getFinalUrl(path, params) {
           return path + params;
    }
}

export default RoutePath