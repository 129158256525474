/**
 * Author : Navin Jaiswal
 * Company : Gramcover Insurance Brokers Pvt. Ltd.
 * Date : 22 Dec 2021
 */

import React, { Component } from 'react';

class ServicesURL extends Component {
    
    static error      = "Error";
    static motorMasterUrl  = 'http://uatquoteapi.gramcover.com/api/';
//'http://devapinp.gramcover.com/';
//'http://192.168.107.101:5012/'; //;
    static motorUrl   = 'http://uatquoteapi.gramcover.com/api/';  //'http://192.168.107.101:5014/';
//'http://motordevapinp.gramcover.com/';
    static motorUrl1  = 'http://uatquoteapi.gramcover.com/api/';   //'http://192.168.107.101:5010/';
    static motorPayamentUrl ='http://uatpay.gramcover.com/';    //'http://192.168.107.101:5024/';
    static downloadPolicyUrl ='http://uatquoteapi.gramcover.com/api/';  //5026/
    static GetCkycStatus = 'http://uatquoteapi.gramcover.com/api/';
    static sharelink = 'http://uatquoteapi.gramcover.com/api/';
    static getPartnerDetailsUrl = 'http://uatquoteapi.gramcover.com';

    static getToken   = "token";
    static make       = 'make';
    static model      = 'model';
    static variant    = 'variant';
    static RegistrationCity = 'RegistrationCity';
    static ncbslab    = 'ncbslab';
    static addons     = 'addons';
    static motoreEnqueryId  = 'GetMotorUniqueRefNumber';
    static insurerQuoteList = 'GetActiveInsurer';
    static motorQuote = 'GetMotorQuote';//getmotorquote';
    static saveMotorProposal = 'GetMotorProposal';
    static state      = 'state';
    static motorQuoteTopValue = 'GetQuotePageTopValue';
    static selectInsurerForBuy = 'UpdateSelectedQuote';
    static citypincode = 'citypincode';
    static financier   = 'financier';
    static financiertype = 'financiertype';
    static occupation  = 'occupation';
    static relationship = 'relationship';
    static insurer    =  'insurer'; 
    static motorPremiumBreakup = 'GetPremiumBreakup';  
    static motorProposalDetail = 'getMotorProposalDetails';
    static downloadMotorPolicy = 'DownloadMotorPolicy';
    static getMinMaxIDV = 'GetMinMaxIDV';    
    static document = 'document';
    static getKycUrl ='GetKycUrl';
    static CheckCkycStatus = 'CheckCkycStatus';
    static partnertoken = 'token';
    static getFastLaneData = 'getfastlanedata';
    static saveCustomerDetails ='SaveCustomerDetails';
    static shareDetails ='send';
}

export default ServicesURL;
