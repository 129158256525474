import React, { Component } from 'react';
import { Navigate, Route, Routes, Switch } from "react-router-dom";
import RoutePath from "../../route_Url/RoutePath";
import Loadable from 'react-loadable';
import Loader from "../Common/Loader";



const HomePage = Loadable({
    loader: () => import('../../Pages/Home'),
    loading: () => { return (null) },

});

const NotFound = Loadable({
    loader: () => import('../../Pages/NotFound/Error404'),
    loading: () => { return (null) },

});

const MoterInsurance = Loadable({
    loader: () => import('../../Pages/FourWheeler/FourWheelerMultiStepFrom'),
    loading: () => { return (null) },

});

const MoterInsuranceQuotePage = Loadable({
    loader: () => import('../../Pages/FourWheeler/GetQuoteForm'),
    loading: () => { return (null) },

});


const MoterPrposalPage = Loadable({
    loader: () => import('../../Pages/FourWheeler/FourWheelerProposalPage'),
    loading: () => { return (null) },

});

const CompareFourWheelerMoterQuote = Loadable({
    loader: () => import('../../Pages/FourWheeler/CompareQuote'),
    loading: () => { return (null) },

});

const PaymentSuccessForQuote = Loadable({
    loader: () => import('../../Component/Common/PaymentSuccess'),
    loading: () => { return (null) },

});

const PaymentFailureForQuote = Loadable({
    loader: () => import('../../Component/Common/PaymentFailure'),
    loading: () => { return (null) },

});

const PaymentStatusForMotorQuote = Loadable({
    loader: () => import('../../Pages/FourWheeler/PaymentStatus'),
    loading: () => { return (null) },

});

const LoginPage = Loadable({
    loader: () => import('../../LoginPage'),
    loading: () => { return (null) },

});

const MainLayout = () => {
    return (
        <div>
            
            <Routes>
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, "")} element={<LoginPage />} title="home Page" />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.login)} element={<LoginPage />} /> 
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.moterInsurance)} element={ <MoterInsurance />} />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.moterQuotePage)} element={<MoterInsuranceQuotePage /> } />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.moterProposalPage)} element={ <MoterPrposalPage />} />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.fourWheelerQuoteCompare)} element={ <CompareFourWheelerMoterQuote /> } />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.paymentSuccessMotorQuote)} element={<PaymentSuccessForQuote /> } />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.paymentFailureMotorQuote)} element={ <PaymentFailureForQuote />  } />
                <Route exact path={RoutePath.getFinalUrl(RoutePath.root, RoutePath.paymentStatusMotorQuote)} element={ <PaymentStatusForMotorQuote />} />

                <Route path="*" element={<HomePage/>}/>
            </Routes>       
        </div>
    )
}
//sessionStorage.getItem("token")? <Navigate to="/car/motor-insurance" />:
export default MainLayout;
