/**
 * Author : Navin Jaiswal
 * Company : Gramcover Insurance Brokers Pvt. Ltd.
 * Date : 07 Jan 2022
 */

import {
    SERVICE_BEGIN,
    SERVICE_SUCCESS,
    SERVICE_FAILURE
} from '../types/ServiceActionTypes';


const initialState = {
    response: {},
    loading: false,
    error: null,
    methodName: ""
};

const serviceCallReducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVICE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                methodName: "",
            };

        case SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response,
                methodName: action.name,
                pageAccess : ""
            };

        case SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                response: {},
                methodName: action.name
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}

export default serviceCallReducer;